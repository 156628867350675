.news-modal-close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
}

.news-modal-summary {
  display: inline-block;
  font-size: 2rem;
  margin-right: 1rem;
  margin-left: 3rem;
}

.news-modal-text {
  margin: 0rem 7rem;
  column-count: 2;
  column-gap: 0px;
}

.news-modal-li {
  font-size: 2.25rem;
  margin-left: 2rem;
  color: var(--heading-dark)
}

#news-modal-popover {
  background-color: var(--heading-dark);
  color: white;
  font-size: 1.75rem;
  max-width: 30vw;
  border-radius: 6px;
  border: 3px solid var(--heading-dark);
}

.news-modal-popover-text {
  font-family: var(--main-font);
  font-weight: 400;
  font-size: 1.75rem;
  color: var(--white-text);
}

.news-container {
  display: grid;
  /* grid-template-columns: 8rem 25rem 80rem; */
  grid-template-columns: 1.25fr 1.75fr 25fr;
  grid-template-rows: 1fr;
  justify-content: start;
  align-content: center;
  grid-gap: 10px;
  /* background-color: #2196F3; */
  /* padding: 10px; */
  margin-right: 5rem;
}

.news-container>div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  text-align: left;
  /* padding: 20px 0; */
  /* font-size: 30px; */
}

@media screen and (min-width:1200px) {
  .news-container {
    grid-template-columns: 1.25fr 4fr 20fr;
  }

  /* And even larger */
  .news-modal-text {
    margin: 1rem 2rem;
    column-count: 3;
    column-gap: 0px;
  }
}

.news-modal-image {
  object-fit: contain;
  width: 100%;
}

.news-modal-header {
  font-size: 4rem;
  color: var(--heading-dark);
  align-self: center;
}

.news-summary {
  margin-left: 10rem;
  margin-right: 10rem;
  font-size: 1.8rem;
  line-height: 1.25;
  justify-self: center;
  align-self: center;
}

@media screen and (min-width:1200px) {
  .news-summary {
    font-size: 2rem;
  }

  /* And even larger */
}