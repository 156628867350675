.private-capital {
  display:block;
  overflow: auto;
  /* background-image: linear-gradient(to right bottom,var(--background-grad-dk), var(--background-grad-dk)), url(./images/coins.png);
  background-size: cover;
  background-position: top; */
       min-height: 100vh;
       padding-bottom: 24px;
}

.private-capital-section {
  text-align: center;
  display:block;
  overflow: auto;
}
.private-capital-main {
  display:block;
  overflow: auto;
  margin-bottom: 16px;
}

.private-capital-header {
  height: 10vh;
  text-align: center;
}
.private-capital-col {
    min-width: 60rem;
}
.private-capital-img-container {
  position: relative;
  text-align: center;
}
.private-capital-image {
  max-width: 70%;
}
.img-all {
  position: absolute;
  width: 7.25vw;
  height: 7.25vw;
  text-align: center;
}
.img-top {
  top: 2.5%;
  left: 42.5%;
  font-size: 1.75rem;
}
.img-right {
  top: 38.5%;
  left: 67.5%;
  font-size: 1.75rem;
}
.img-bottom {
  top: 75%;
  left: 42.5%;
  font-size: 1.75rem;
}
.img-center {
  top: 39%;
  left: 42.5%;
  font-size: 2.25rem;
}
.img-left {
  top: 38.5%;
  left: 17.5%;
  font-size: 1.75rem;
}
.img-text {
  color: var(--white-text);
  font-family: var(--main-font);
  line-height: 1.40;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.private-capital-text{
  font-size: 2.5rem;
  /* margin-bottom: 3rem;
  margin-right: 8rem; */
  text-align: left;
  margin: 4rem 4rem 4rem 0rem;
}
@media screen and (min-width:800px) {
  .private-capital-text {
  margin-right: 8rem;
  } /* And even larger */
}
.return-to-our-firm {
  font-size: 2rem;
  color: var(--heading-dark);
  cursor: pointer;
}
.return-to-our-firm:hover {
  color: (--white-text);
  background-color: var(--heading-dark);
}