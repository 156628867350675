.txn {
  min-height: 100vh;
  /* background-image: linear-gradient(to right bottom,var(--background-grad-dk), var(--background-grad-dk)), url(./images/transactions-background.png); */
  background-size: cover;
  background-position: top;
}

.txn-section {
  text-align: center;
  display: block;
  overflow: auto;
}

.txn main {
  display: block;
  overflow: auto;
  margin-bottom: 16px;
}

.txn-header {
  /* margin-top: -5rem; */
  height: 10vh;
  text-align: center;
}

.txn-card-div {
  margin: 2rem 14rem;
  display: block;
  overflow: auto;
}

.txn-card {
  width: 32rem;
  height: 32rem;
  position: relative;
  background-color: var(--heading-dark);
}

.txn-card-content {
  width: 28rem;
  height: 28rem;
  position: relative;
  margin: auto;
  background-color: var(--white-text);
}

.txn-division {
  display: block;
  position: absolute;
  /* top: 0px;
  left: 25%; */
  text-transform: uppercase;
  text-align: center;
}

.txn-nologo,
.txn-logo {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 100%;
}

.txn-nologo {
  font-size: 3rem;
  line-height: 1;
  color: var(--heading-dark);
}

.card-back {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}


.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: .25rem;
}

/* .image-flip:hover .backside .txn-nologo,
.image-flip.hover .backside .txn-logo {
opacity: 0;
} */

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  opacity: 0;
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
  transform: translateZ(0);
}

.frontside {
  position: relative;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
  -webkit-box-shadow: 7px 7px 9px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 7px 7px 9px -4px rgba(0, 0, 0, 0.8);
  box-shadow: 7px 7px 9px -4px rgba(0, 0, 0, 0.8);
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 7px 7px 9px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 7px 7px 9px -4px rgba(0, 0, 0, 0.8);
  box-shadow: 7px 7px 9px -4px rgba(0, 0, 0, 0.8);
}

.frontside,
.backside,
.txn-logo,
.txn-nologo {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
  height: 32rem;
}

.backside .card a {
  font-size: 18px;
  color: #007b5e !important;
}

.frontside .card .card-title,
.backside .card .card-title {
  /* margin-top: 2rem; */
  width: 100%;

}

.card-title {
  /* margin-top: 1rem; */
  line-height: 2;
  font-size: 2.5rem;
  color: var(--heading-medium);
  text-align: center;
}

.test1 {
  position: absolute;
  top: 65%;
  text-align: center;
  margin: auto;
  left: 18.75%;
}

.test2 {
  position: absolute;
  top: 25%;
}

.txn-amount {
  margin-top: -1rem;
  font-size: 2.5rem;
  color: var(--heading-dark)
}

.back-title,
.back-text {
  /* text-transform: capitalize; */
  font-size: 2rem;
}

.back-title {
  color: var(--heading-medium);
}

.back-text {
  font-size: 2rem;
  color: var(--heading-dark);
  line-height: 1;
  margin: -.25rem .5rem 1rem .5rem;
}


.frontside .card .card-body img {
  width: 95%;
  height: auto;
}